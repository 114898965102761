import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import Container from 'components/Container/Container';
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Title from 'components/Title/Title';

import { btnLinkDark } from 'styles/components/links.module.scss';
import {
  banner,
  contentContainer,
  content,
  img,
} from 'styles/pages/etaireia.module.scss';

const CompanyPage = () => {
  return (
    <Layout fullWidth={true}>
      <Seo title="Εταιρεία" />
      <div className={banner}>
        <Title isBanner={true}>Η Εταιρεία</Title>
      </div>
      <Container withPadding={true}>
        <div className={contentContainer}>
          <div className={content}>
            <h2>Κρητικό Παραδοσιακό Κόσμημα Minoera</h2>
            <p>
              Κατασκευάζοντας κοσμήματα από το 2006, η εταιρεία μας συνεχίζει να
              αναπτύσσεται με υπευθυνότητα και συνέπεια προς τους πελάτες της. Η
              ανάπτυξη μας παραμένει σταθερά ανοδική, προσφέροντας τόσο στην
              Ελλάδα,όσο και στο εξωτερικό, προϊόντα ποιοτικά και αφήνοντας το
              δικό μας ξεχωριστό αποτύπωμα στο χώρο της ελληνικής
              κοσμηματοποιίας.
            </p>
            <p>
              Εκτός από τα παραδοσιακά, κατασκευάζουμε αρχαϊκά και σύγχρονα
              κοσμήματα, μπολιασμένα πάντα στο πολυχιλιετές δέντρο της Κρητικής
              πολιτιστικής κληρονομιάς.
            </p>
            <Link className={btnLinkDark} to="/epikoinonia">
              ΕΠΙΚΟΙΝΩΝΗΣΤΕ ΜΑΖΙ ΜΑΣ
            </Link>
          </div>
          <StaticImage
            className={img}
            src="../images/company.jpg"
            alt="Κρητικιά με παραδοσιακή στολή - Κρητικό Παραδοσιακό Κόσμημα Minoera"
          />
        </div>
      </Container>
    </Layout>
  );
};

export default CompanyPage;
